import {apiEnum} from "../../../enums/apiEnum";
import {useAppAxios} from "../../../../app/hooks";

export const apiTaskEnvironmentDetialByUrl = async (clientId: number) => {
  return await useAppAxios.post(apiEnum.ApiTaskEnvironmentDetailByUrl, {}, {
    params: {
      url: window.location.origin + '/' + clientId
    }
  }).then(function (response) {
    return response
  }).catch(function (error) {
    console.log(error)
    return {
      data: {
        status: {
          code: 500
        }
      }
    }
  })
}
