import {apiEnum} from "../../../enums/apiEnum";
import {useAppAxios} from "../../../../app/hooks";

export const apiTaskBundleUpdateStatus = async (id: number, status: string) => {
    return await useAppAxios.post(apiEnum.ApiTaskBundleUpdateStatus, {}, {
        params: {
            id: id,
            status: status
        }
    }).then(function (response) {
        return response
    }).catch(function (error) {
        console.log(error)
        return {
            data: {
                status: {
                    code: 500
                }
            }
        }
    })
}
