import {apiEnum} from "../../../enums/apiEnum";
import {useAppAxios} from "../../../../app/hooks";

export const apiTaskInfoUnAssign = async (tasks: number[]) => {
  return await useAppAxios.post(apiEnum.ApiTaskInfoUnassign, {
    task: tasks
  }, {}).then(function (response) {
    return response
  }).catch(function (error) {
    console.log(error)
    return {
      data: {
        status: {
          code: 500
        }
      }
    }
  })
}
