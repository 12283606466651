import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import '../src/common/styles/main.scss'
import App from "./app/App";
import {Provider} from "react-redux";
import {store} from "./app/store";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {EnvironmentNotFoundPageContainer} from "./views/environmentNotFound/EnvironmentNotFoundPageContainer";
import {globalConfig, globalConfigUrl} from "./configuration/config";

axios.get(globalConfigUrl)
    .then((response) => {
        globalConfig.set(response.data);

        return   <React.StrictMode>
            <Provider store={store}>
                <BrowserRouter>
                    <Routes>
                        <Route path={'/:id/*'} element={<App/>} />
                        <Route path={'/'} element={<EnvironmentNotFoundPageContainer />} />
                    </Routes>
                </BrowserRouter>

            </Provider>
        </React.StrictMode>;
    })
    .catch(() => {
        return <p style={{color: "red", textAlign: "center"}}>Error while fetching global config</p>;
    })
    .then((reactElement) => {
        ReactDOM.render(
            reactElement,
            document.getElementById("root")
        );
    });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
