import {apiEnum} from "../../../enums/apiEnum";
import {useAppAxios} from "../../../../app/hooks";
import {mapVariableTaskData} from "../../../utils/mapVariableTaskData";
import {TaskInterface} from "../../../interfaces/TaskInterface";
import {mapVariableBundleData} from "../../../utils/mapVariableBundleData";

export const apiTaskBundleDetail = async (id: number) => {
    return await useAppAxios.post(apiEnum.ApiTaskBundleDetail, {}, {
        params: {
            id: id,
        }
    }).then(function (response) {

        response.data.items = {
            ...response.data.items,
            variableBundleData: mapVariableBundleData(response.data.items)
        }


        response.data.items.tasks = response.data.items.tasks.map((task: TaskInterface) => {
            return {
                ...task,
                variableTaskData: mapVariableTaskData(task)
            }
        })

        console.log(response)

        return response
    }).catch(function (error) {
        console.log(error)
        return {
            data: {
                status: {
                    code: 500
                }
            }
        }
    })
}
