import {apiEnum} from "../../../enums/apiEnum";
import {useAppAxios} from "../../../../app/hooks";
import {UserInterface} from "../../../interfaces/UserInterface";

export const apiTaskAuthLogin = async (user: UserInterface) => {
    return await useAppAxios.post(apiEnum.ApiTaskAuthLogin, {
        email: user.email,
        password: user.password
    }).then(function (response) {
        return response
    }).catch(function (error) {
        console.log(error)
        return {
            data: {
                status: {
                    code: 500
                }
            }
        }
    })
}
