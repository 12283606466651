interface DynamicConfig {
    apiUrl: string,
    key: string,
    secret: string,
    environment: "DEV" | "TEST" | "ACC" | "PROD"
}

export const defaultConfig: DynamicConfig = {
    apiUrl: "https://backoffice-vdl.idot.cloud/api",
    key: '3b149744-be4e-4278-96fe-41b29f809744',
    secret: 'c9161bde-c339-476e-be92-08208389d3f9',
    environment: "PROD"
};

class GlobalConfig {
    config: DynamicConfig = defaultConfig;
    notDefinedYet: boolean = true;

    get(): DynamicConfig {
        if (this.notDefinedYet) {
            console.log('WARNING: No config is loaded, PRODUCTION IS USED!');
        }
        return this.config;
    }

    set(value : DynamicConfig) {
        if (this.notDefinedYet) {
            this.config = {
                ...this.config,
                ...value
            };
            this.notDefinedYet = false;
        } else {
            throw new Error("Global config has already been defined");
        }
    }
}

export const globalConfig = new GlobalConfig();

export const globalConfigUrl = "/environment.json";