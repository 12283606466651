import {apiEnum} from "../../../enums/apiEnum";
import {useAppAxios} from "../../../../app/hooks";

export const apiTaskItemLinkAll = async (task: number, items: number[]) => {
    return await useAppAxios.post(apiEnum.ApiTaskItemLinkAll, {
        items: items,
        overwrite: true
    }, {
        params: {
            task: task,
        }
    }).then(function (response) {
        return response
    }).catch(function (error) {
        console.log(error)
        return {
            data: {
                status: {
                    code: 500
                }
            }
        }
    })
}
