export const apiEnum = {
    ApiAuthConnect: '/auth/connect',
    ApiAuthRefresh: '/auth/refresh',
    ApiAuthMe: '/auth/me',

    ApiTaskAuthLogin: '/task/auth/login',
    ApiTaskAuthPinCode: '/task/auth/pin-code',

    ApiTaskItemDetail: '/task/detail',
    ApiTaskItemDetailByCode: '/task/detail-by-code',
    ApiTaskItemLink: '/task/link',
    ApiTaskItemLinkAll: '/task/link-all',
    ApiTaskItemUnlink: '/task/unlink',
    ApiTaskItemUnlinkAll: '/task/unlink-all',

    ApiTaskInfoAssign: '/task/info/assign',
    ApiTaskInfoUnassign: '/task/info/unassign',
    ApiTaskInfoDetail: '/task/info/detail',
    ApiTaskInfoDownloadPdf: '/task/info/download-pdf',
    ApiTaskInfoList: '/task/info/list',
    ApiTaskInfoTotals: '/task/info/totals',
    ApiTaskInfoUpdateStatus: '/task/info/update-status',

    ApiTaskBundleAddBulkTasks: '/task/bundle/add-bulk-tasks',
    ApiTaskBundleAddTask: '/task/bundle/add-task',
    ApiTaskBundleCreate: '/task/bundle/create',
    ApiTaskBundleDelete: '/task/bundle/delete',
    ApiTaskBundleDetail: '/task/bundle/detail',
    ApiTaskBundleList: '/task/bundle/list',
    ApiTaskBundleRemoveAllTasks: '/task/bundle/remove-all-tasks',
    ApiTaskBundleRemoveTask: '/task/bundle/remove-task',
    ApiTaskBundleUpdate: '/task/bundle/update',
    ApiTaskBundleUpdateStatus: '/task/bundle/update-status',
    ApiTaskBundleUpdateTaskOrder: '/task/bundle/update-task-order',

    ApiTaskEnvironmentDetailById: '/task/environment/detail-by-id',
    ApiTaskEnvironmentDetailByUrl: '/task/environment/detail-by-url',

    ApiTaskUserList: '/task/user/list',
    ApiTaskUserLocations: '/task/user/locations',

}

