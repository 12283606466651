import {apiEnum} from "../../../enums/apiEnum";
import {useAppAxios} from "../../../../app/hooks";

export const apiTaskBundleCreate = async (userId: number, date: string, tasks: number[], type: string, transportType: string, deliveryType: string, locationId?: number) => {
    return await useAppAxios.post(apiEnum.ApiTaskBundleCreate, {
        userId: userId,
        date: date,
        tasks: tasks,
        type: type,
        transportType: transportType,
        deliveryType: deliveryType,
        partnerLocationId: locationId
    }).then(function (response) {
        return response
    }).catch(function (error) {
        console.log(error)
        return {
            data: {
                status: {
                    code: 500
                }
            }
        }
    })
}
