import {apiEnum} from "../../../enums/apiEnum";
import {useAppAxios} from "../../../../app/hooks";
import {format, isToday} from "date-fns";
import {TaskInterface} from "../../../interfaces/TaskInterface";
import {mapVariableTaskData} from "../../../utils/mapVariableTaskData";

export const apiTaskInfoList = async (type: string, deliveryType: string, transportType: string, employeeType: string, date: Date, archive: boolean, notBundled: boolean, limit: number, page?: number, userId?: number, locationId?: number) => {

  const data = {} as any
  const params = {
    type: type,
    deliveryType: deliveryType,
    transportType: transportType,
    employeeType: employeeType,
    limit: limit,
    page: page,
  } as any

  if(!!userId){
    data.taskUserId = userId
  }

  if (!isToday(date) || archive) {
    data.date = format(date, 'yyyy-MM-dd')
  }


  if (notBundled) {
    data.bundled = false
    data.status = 'open'
  }

  if (locationId) {
    data.partnerLocationId = locationId
  }

  data.archive = archive

  return await useAppAxios.post(apiEnum.ApiTaskInfoList, data, {
    params: params
  }).then(function (response) {


    response.data.items.data = response.data.items?.data?.map((task: TaskInterface) => {
      return {
        ...task,
        variableTaskData: mapVariableTaskData(task)
      }
    })

    return response

  }).catch(function (error) {
    console.log(error)
    return {
      data: {
        status: {
          code: 500
        }
      }
    }
  })
}
