import {apiEnum} from "../../../enums/apiEnum";
import {useAppAxios} from "../../../../app/hooks";

export const apiTaskAuthPinCode = async (pinCode: string, environmentId?: number) => {

    const client = JSON.parse(localStorage.getItem(environmentId + '-client') + '');

    return await useAppAxios.post(apiEnum.ApiTaskAuthPinCode, {
        pinCode: Number(pinCode),
        clientId: client.id
    }).then(function (response) {
        return response
    }).catch(function (error) {
        console.log(error)
        return {
            data: {
                status: {
                    code: 500
                }
            }
        }
    })
}
