import {apiEnum} from "../../enums/apiEnum";
import axios from "axios";
import {globalConfig} from "../../../configuration/config";

export const apiAuthConnect = async () => {
    return await axios.post(globalConfig.get().apiUrl + apiEnum.ApiAuthConnect, {
        key: globalConfig.get().key,
        secret: globalConfig.get().secret
    }).then(function (response) {
        return response
    }).catch(function (error) {
        //dispatch(setAuthApiError(error.response.status))
        return error
    })
}
