import {apiEnum} from "../../../enums/apiEnum";
import {useAppAxios} from "../../../../app/hooks";

export const apiTaskItemDetailByCode = async (code: string) => {
    return await useAppAxios.post(apiEnum.ApiTaskItemDetailByCode, {}, {
        params: {
            code: code,
            overwrite: true
        }
    }).then(function (response) {
        return response
    }).catch(function (error) {
        console.log(error)
        return {
            data: {
                status: {
                    code: 500
                }
            }
        }
    })
}
